import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Message anonyme 👤' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Vous avez la possibilité de m'envoyer un message anonyme si vous le
        souhaitez 👀.
      </Text>
    </Card>
  </Section>
)

export default Commitment
